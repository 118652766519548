import React, { useEffect, useState } from "react";
import Logo from '../imgs/bounty.png';
import Human from '../imgs/being_humna.png';
import './Bounty.css';
import { Link } from "react-router-dom";
import axios from "axios";
import { Url } from "./RequireAuth";
import swal from "sweetalert";

const Navbar = () => {

    const [Notification_count, setNotificationCount] = useState(0);
    const [ShowNotifi, setShowNotifi] = useState(false)
    const [Notifi, setNotifi] = useState([])

    const Notification = () => {
        Notifi.length = 0;
        const email = localStorage.getItem("userName")
        axios.get(`${Url}api/Influencer/approveShow/${email}`)
            .then((res) => {
                if(res.data==="No Record found!!")
                {
                    setNotificationCount(0)
                    Notifi.length=0;
                    console.log(Notification_count)
                }
                else{
                    setNotificationCount(res.data.length);
                    console.log(res.data)
                    Notifi.push(res.data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const SelectIntrest = (name, status) => {
        const email = localStorage.getItem("userName")
        axios.post(`${Url}api/Influencer/SelectInterest`, {
            email: email,
            name: name,
            status: status
        })
            .then((res) => {
                swal(`You are ${status} for this Campaign`, "", "success");
                Notification();
            })
            .catch((err) => {

            })
    }

    useEffect(() => {
        Notification()
    }, [])
    return (
        <>
            <main className="logoHead">
                <div className="logo-head">
                    <div className="none"></div>
                    <Link to="/Influencer/profile">
                        <img src={Logo} alt="logo" width="180px" className="bountylogo" />
                    </Link>

                    <div className="notification">
                        <div className="notificatio_icon">

                                    <button className="btn notif" onClick={() => { setShowNotifi(!ShowNotifi) }}>
                                        <i className="fa-solid fa-bell fa-xl"></i>
                                    </button>

                            {
                                Notification_count == 0
                                    ?
                                    null
                                    :
                                    <span>{Notification_count}</span>
                            }

                            {
                                ShowNotifi
                                    ?
                                    <div className="Notification_details">
                                        {
                                            Notifi.map((elem, index) => {
                                                return (
                                                    <>
                                                        <table className="table">
                                                            <tr>
                                                                <td>
                                                                    <label key={index}>{elem[index].NameOfCamp}</label>
                                                                </td>

                                                                <td>
                                                                    <button className="btn intrested" onClick={() => { SelectIntrest(elem[index].NameOfCamp, "Interested") }}>
                                                                        <i class="fa fa-check" aria-hidden="true"></i>
                                                                    </button>
                                                                </td>

                                                                <td>
                                                                    <button className="btn notintrested" onClick={() => { SelectIntrest(elem[index].NameOfCamp, "NotInterested") }}>
                                                                        <i class="fa fa-times" aria-hidden="true"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                    :
                                    null

                            }
                        </div>


                        <Link to="/Influencer/profile">
                            <img src={Human} alt="human" title="Login" className="human" />
                        </Link>
                    </div>
                </div>
            </main>
        </>
    );
}
export default Navbar;