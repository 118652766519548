 const UserData = [
    {
        id:1,
        gender:"Male",
        year:2014 ,
        userGrowth:2000 ,
        userLost :300,
        bgc:"aqua",
    },{
        id: 2 ,
        gender:"Female",
        year : 2015,
        userGrowth:4555 ,
        userLost :388,
        bgc:"blue",
    },{
        id: 3 ,
        year : 2016,
        userGrowth : 7123,
        userLost : 123,
        bgc:"green",
    },{
        id: 4 ,
        year : 2017 ,
        userGrowth :36567 ,
        bgc:"brown",
        userLost :11
    },{
        id: 5 ,
        year : 2018 ,
        userGrowth : 22313,
        bgc:"white",
        userLost : 111,
    },{
        id: 6 ,
        year : 2023 ,
        userGrowth : 25313,
        userLost : 1114,
        bgc:"violet",
    }
    ,{
        id: 7 ,
        year : 2024 ,
        userGrowth : 95313,
        userLost : 1114,
        bgc:"red",
    }
]


export default UserData;