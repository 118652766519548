import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { baseUrl } from "../PrivateRoutes";
import axios from "axios";
import '../index.css';
const Navbar = () => {

    const location=useLocation()
    const UserAid = window.localStorage.getItem("Access-Id");
    const UserAname = window.localStorage.getItem("LoggedInUserName-");
    const access4super = window.localStorage.getItem("supConfirm-");

    const [show,setShow]=useState(false);





    useEffect(() => {
        GetUserName();
    }, []);




    // 1. GETADMIN DATA BY ID for admin name___API-1 
    const GetUserName = () => {
        axios.get(`${baseUrl}/api/Admin/getAdminDataById/${UserAid}`)
            .then((resp) => {
                console.log("Get AdminData By Id Response --hurhurhhurr-", resp.data.admin_name)
                // setAdminName(resp.data.admin_name)

                window.localStorage.setItem("LoggedInUserName-", resp.data.admin_name);
                // console.log('test',UserAname)
                    SupConfirm();

                // console.log("this is the logged in admins data -  ", resp.data)
                // console.log("admin name is - ", resp.data.admin_name)
            }).catch((error) => {
                console.log("Get AdminData By Id Error - ", error)
            })
    }


    // 2.   SUPER ADMIN VERIFICATION 0/1_____API-2
    const SupConfirm = () => {
        axios.get(`${baseUrl}/api/Admin/verifySuperAdmin/${UserAname}`)
            .then((res) => {
                console.log("the super admin details -", res.data)
                window.localStorage.setItem("supConfirm-", res.data);
                console.log("Verify Super Admin Response -", res.data)

                setShow(true);

                // window.localStorage.setItem("allow  - ", res.data.ala);
            }).catch((err) => {
                console.log("Verify Super Admin Error - ", err)
            })
    }














    return (
        <>
        {
            show
            ?
            <ol className="nav d-md-block text-uppercase fs-5 py-4" >
                <Link to="profile" className="text-decoration-none" > <i className="fa-solid fa-user  px-3 "></i> Profile </Link>
                <hr />
                <Link to="security" className="text-decoration-none" > <i className="fa-solid fa-user-shield mr-2 pt-3 px-3 "></i>Security </Link>
                <hr />
                <Link to="activity" className="text-decoration-none"> <i className="fa-solid fa-chart-line mr-2 pt-3 px-3 "></i> Activity</Link>
                <hr />
                <Link to="billing" className="text-decoration-none"> <i className="fa-solid fa-money-check-alt mr-2  px-3 pt-3"></i>Billing</Link>
                <hr />
                <Link to="insight" className="text-decoration-none"><i className="fa-sharp fa-solid fa-eye mr-2 pt-3 px-3 "></i> Insights  </Link>
                <hr />


                {
                    (access4super === "ala=1") ?
                        <Link to="removeAdmin" className="text-decoration-none" > <i className="fa-solid fa-user-check mr-2 pt-3 px-3 "></i>Remove Admin </Link>
                        :
                        null
                }



            </ol>
            :
            null
        }
            
        </>
    );
}
export default Navbar;