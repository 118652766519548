import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Base } from './Auth'
import swal from 'sweetalert'

export default function IntrestedList() {

    const [IntrestedDetails, setIntrestedDetails] = useState([]);
    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);

    const IntrestedList = (campName) => {
        console.log(campName)
        IntrestedDetails.length = 0;
        axios.get(`${Base}api/Brands/InterestedShow/${campName}`)
            .then((res) => {
                IntrestedDetails.push(res.data);
                if(IntrestedDetails[0]=="No Record Found!")
                {
                    setShow(false);
                }
                else{
                    setShow(true);
                }
                console.log(IntrestedDetails);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const ShowBrands = () => {
        let at = localStorage.getItem("BrandName");
        axios.post(`${Base}api/Campaign/getCampaignDetails/Brand`, {
            brand: at
        }).then((res) => {
            console.log(res.data)
            if (res.data === "YOU HAVEN'T CREATED CAMPAIGN!!...") {
                console.log("nodata")
            } else {
                setData(res.data)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const CampaginDetails = (name, campname) => {
        let brand = localStorage.getItem("BrandName");
        axios.post(`${Base}api/Campaign/getFormDetails/Brand`, {
            brand: brand
        })
            .then((res) => {
                interraction(name, campname, res.data.CampaignDetails[0].Type);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const finalApprove = (email, campname, status, name) => {

        axios.post(`${Base}api/Brands/finalApprovalService`, {
            "email": email,
            "name": campname,
            "isActive": status
        })
            .then((res) => {
                console.log(res);
                CampaginDetails(name, campname)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const interraction = (name, campnames, type) => {
        let brand = localStorage.getItem("BrandName");
        axios.post(`${Base}api/Campaign/Intection`, {
            brandname: brand,
            influencername: name,
            type: type,
            name: campnames
        }).then((res) => {
            if(type=="yes")
            {
                swal("Approve", "", "success");
                IntrestedList()
            }
            else{
                swal("Disapprove", "", "success")
                IntrestedList()
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        ShowBrands();
    }, [])

    return (
        <div>
            <div className="list-campaign">
                <h2>Campaign's Influencer List</h2>
            </div>

            <div>
                <label>Select Campaign</label>
                <select onChange={(e) => { IntrestedList(e.target.value) }}>
                    <option selected hidden>Select Campaign...</option>
                    {
                        data.map((elem) => {
                            return (
                                <option>{elem.nameOfCamp}</option>
                            )
                        })
                    }
                </select>
            </div>
            <table className="table intresed_table">
                <thead>
                    <tr>
                        <th>
                            Campaign Name
                        </th>

                        <th>
                            Influencer Name
                        </th>

                        <th>
                            Email
                        </th>

                        <th>
                            Total Followers
                        </th>

                        <th>
                            Total Following
                        </th>

                        <th>
                            Instagram Handle
                        </th>

                        <th>
                            Total Media Uploaded
                        </th>

                        <th>
                            Approve
                        </th>

                        <th>
                            Not Approved
                        </th>
                    </tr>
                </thead>

                <tbody>

                    {
                        IntrestedDetails[0] != "No Record Found!" && show
                            ?
                            IntrestedDetails.map((elem, index) => {
                                return (
                                    <tr>
                                        <td>
                                            <label>{elem[index].Campname}</label>
                                        </td>

                                        <td>
                                            <label>{elem[index].name}</label>
                                        </td>

                                        <td>
                                            <label>
                                                <a>
                                                    {elem[index].email}
                                                </a>
                                            </label>
                                        </td>

                                        <td>
                                            <label>{elem[index].followers}</label>
                                        </td>

                                        <td>
                                            <label>{elem[index].following}</label>
                                        </td>

                                        <td>
                                            <label>{elem[index].instaUsername}</label>
                                        </td>

                                        <td>
                                            <label>{elem[index].mediaCount}</label>
                                        </td>

                                        <td>
                                            <button className="btn intrested" onClick={() => { finalApprove(elem[index].email, elem[index].Campname, "yes", elem[index].name) }}>
                                                <i class="fa fa-check" aria-hidden="true"></i>
                                            </button>
                                        </td>



                                        <td>
                                            <button className="btn notintrested" onClick={() => { finalApprove(elem[index].email, elem[index].Campname, "no", elem[index].name) }}>
                                                <i class="fa fa-times" aria-hidden="true"></i>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            null
                    }
                </tbody>
            </table>
        </div>
    )
}
