import React, { useState } from "react";
import '../Index.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Base } from "./Auth";
import swal from "sweetalert";
import { toast } from 'react-toastify';
import { CirclesWithBar } from 'react-loader-spinner'

const Brandsign = () => {
    const [brand, setBrand] = useState("");
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [password, setPassword] = useState("");
    const [rePassword, setRePassword] = useState("");
    const [phone, setPhone] = useState("");
    const [instagram, setInstagram] = useState("");
    const navigate = useNavigate();
    const [isLoader, setIsloader] = useState(false);
    const [otp, setOtp] = useState('');
    const [ShowOTP, setShowOTP] = useState(false);

    const userSubmit = () => {
        setIsloader(true)
        // console.log({ brand, userName, email, street, city, state, zip, password, rePassword, phone, instagram });
        let userData = { brand, userName, email, street, city, state, zip, password, rePassword, phone, instagram };

        if (userData.brand == "") {
            toast.info('Enter Brand', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setIsloader(false)
        }
        else if (userData.userName == '') {
            toast.info('Enter User Name', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setIsloader(false)
        }
        else if (userData.email == '') {
            toast.info('Enter Email Address', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setIsloader(false)
        }
        else if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(userData.email) === false) {
            toast.info('Enter Valid Email Address', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setIsloader(false)
        }
        else if (userData.street == '') {
            toast.info('Enter Street', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setIsloader(false)
        }
        else if (userData.city == '') {
            toast.info('Enter City Name', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setIsloader(false)
        }
        else if (userData.state == '') {
            toast.info('Enter State Name', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setIsloader(false)
        }
        else if (userData.zip == '') {
            toast.info('Enter 6 Digit Pin Code', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setIsloader(false)
        }
        else if (zip.length !== 6) {
            toast.info('Enter 6 digit Pin Code', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setIsloader(false)
        }
        else if (userData.password == '') {
            toast.info('Enter Password', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setIsloader(false)
        }
        else if (userData.rePassword == '') {
            toast.info('Enter Repassword', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setIsloader(false)
        }
        else if (userData.password !== userData.rePassword) {
            toast.info('Password not Matched', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setIsloader(false)
        }
        else if (userData.phone == '') {
            toast.info('Enter Phone no.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setIsloader(false)
        }
        else if (phone.length < 10) {

            toast.info('Enter Valid Mobile No.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setIsloader(false)
        }
        else {
            console.log(userData)
            axios.post(`${Base}api/Brands/brandsRegister`, {
                Brands_name: userData.brand,
                Brands_username: userData.userName,
                email: userData.email.toLowerCase(),
                Street_Address: userData.street,
                city: userData.city,
                State: userData.state,
                postal_code: userData.zip,
                password: userData.password,
                repassword: userData.rePassword,
                phone: userData.phone,
                Instagram_link: userData.instagram
            })
                .then((res) => {
                    swal("Brand Register Successful", "", "success");
                    setIsloader(false)
                    navigate('/threads/createdashboard',{state:{data:userData}});
                }).catch((err) => {
                    console.log("err", err);
                    setIsloader(false)
                    swal("Recheck The form", "", "error");
                })
        }

    }

    const SendOTP = async () => {
        setIsloader(true);
        if (phone.length != 10 || phone === ' ') {
            toast.info('Enter Valid Mobile No.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setIsloader(false)
        }
        else {
            await axios.post(`${Base}api/Brands/sendOtp`, {
                phone: phone,
                name: userName
            })
                .then((res) => {
                    console.log(res);
                    if(res.data=="number is already registered!!")
                    {
                        toast('This Number is Already Verified...', {
                            position: "top-right",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        setShowOTP(true);
                        setIsloader(false);
                    }
                    else{
                        setIsloader(false)
                        setShowOTP(true)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setIsloader(false)
                })
        }
    }

    const VerifyOTP=async()=>{
        setIsloader(true);
        if(otp=='' || otp.length!=5)
        {
            toast.info('Enter 5 Digit OTP', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setIsloader(false);
        }
        else{
            await axios.post(`${Base}api/Brands/otpVerify`,{
                otp:otp,
                phone:phone
            })
            .then((res)=>{
                console.log(res.data)
                swal("Verified", "", "success")
                // setShowOTP(false)
                setIsloader(false);
                setShowOTP(true);
            })
            .catch((err)=>{
                console.log(err);
                toast('Enter Correct OTP', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "Dark",
                });
                setIsloader(false);
            })
        }

    }
    return (
        <>
            {
                isLoader
                    ?
                    <>
                        <div className='loaders'>
                            <div className='overlay'>
                            </div>
                            <CirclesWithBar
                                height="100"
                                width="100"
                                color="#f0534e"
                                wrapperClass=""
                                visible={true}
                                outerCircleColor=""
                                innerCircleColor=""
                                barColor=""
                                ariaLabel='circles-with-bar-loading'
                            />
                        </div>
                    </>
                    :
                    null
            }
            <section id="brand-signForm">
                <div className="brandsign">
                    <div className="brand-head">
                        <h2>Brand Sign Up</h2>
                    </div>
                    <div className="brand-fields">
                        <div id="brand-name">
                            <label>Brand Name</label>
                            <input type="text" value={brand} onChange={(e) => { setBrand(e.target.value) }} className="username" placeholder="Eg.Puma" />
                        </div>
                        <div id="brand-name">
                            <label>Brand Username</label>
                            <input type="text" value={userName} onChange={(e) => { setUserName(e.target.value) }} className="username" placeholder="Username" />
                        </div>
                        <div id="brand-name">
                            <label>Email </label>
                            <input type="email" value={email} onChange={(e) => { setEmail(e.target.value) }} className="username" placeholder="Email" />
                        </div>
                        <div id='address'>
                            <label className="user">Address</label>
                            <input type="text" value={street} onChange={(e) => { setStreet(e.target.value) }} id='street' name='address' placeholder='Street' />
                            <div id='both'>
                                <input type="text" value={city} onChange={(e) => { setCity(e.target.value) }} id='city' name='city' placeholder='City' />
                                <input type="text" value={state} onChange={(e) => { setState(e.target.value) }} id='state' name='state' placeholder='State/Province' />
                            </div>
                            <input type="text" value={zip} onChange={(e) => { setZip(e.target.value) }} id='zip' name='zip' placeholder='Zip / postal code' />
                        </div>
                        <div id="brand-name">
                            <label>Password</label>
                            <input type="password" value={password} onChange={(e) => { setPassword(e.target.value) }} className="username" name="passsword" placeholder="Password" />
                        </div>
                        <div id="brand-name">
                            <label>Re-Password</label>
                            <input type="password" value={rePassword} onChange={(e) => { setRePassword(e.target.value) }} className="username" name="passsword" placeholder="Password" />
                        </div>
                        <div id="brand-name">
                            <label>Phone</label>
                            <input type="tel" value={phone} onChange={(e) => { setPhone(e.target.value) }} className="username" name="phone" placeholder="Phone Number" />
                        </div>
                        <div id="brand-name">
                            <button className="OTP_verifier btn" onClick={() => { SendOTP() }}>
                                Send OTP
                            </button>
                        </div>

                        {
                            ShowOTP
                                ?
                                <div id="brand-name">
                                    <label>OTP</label>
                                    <input type="tel" value={otp} onChange={(e) => { setOtp(e.target.value) }} className="username"/>
                                    <button className="btn OTP_verifier" onClick={()=>{VerifyOTP()}}>
                                        Verify
                                    </button>
                                </div>
                                :
                                null
                        }


                        <div id="brand-name">
                            <label>Instagram</label>
                            <input type="text" value={instagram} onChange={(e) => { setInstagram(e.target.value) }} className="username" name="instagram" placeholder="Instagram Username" />
                        </div>
                        <div className="button-submit">
                            <button className={ShowOTP==false?"disable_btn":null} onClick={userSubmit} disabled={!ShowOTP}>Submit</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Brandsign;