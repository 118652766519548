import React from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { Base } from './Auth'
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { useState } from 'react'

export default function BrandsFAQ() {
    const location = useLocation();
    const navigate = useNavigate();

    const [FAQData, setFAQData] = useState([]);

    const [Question, setQuestion] = useState('');
    const [Answer, setAnswer] = useState('');

    const submit = () => {
        axios.post(`${Base}api/Campaign/compaignFrom/${location.state.name.val}`, {
            campaign: location.state.name.name,
            hashtag: location.state.name.hastag,
            brief: location.state.name.brief,
            logo: "logo",
            minFollower: parseInt(location.state.name.followers),
            type: location.state.name.type,
            from: location.state.name.from,
            media: location.state.name.mediaType,
            to: location.state.name.to,
            special: location.state.name.requirements,
            prize: parseInt(location.state.name.prize)
        }).then((response) => {
            SubmitFAQ()
        }).catch((error) => {
            console.log(error)
            swal(error.message, "", "success")
        })
    }

    const SubmitFAQ = async () => {
        console.log(FAQData)
        await axios.post(`${Base}api/Campaign/faq`, {
            faq: FAQData,
            camp: location.state.name.name
        })
            .then((res) => {
                console.log(res)
                swal("Your Campagin was created", "", "success").then(() => {
                    navigate("/threads")
                });
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const SaveFAQ = () => {
        FAQData.push({
            "ques": Question,
            "ans": Answer
        })

        swal("FAQ Added", "", "success");
    }

    return (
        <section id="brief">
            <div className="form-elements">
                <div className="brief-head">
                    <h3>Campaign FAQ</h3>
                </div>

                <p></p>

                <div className="brief-input">
                    <label>Question</label>
                    <input type="text" value={Question} onChange={(e) => { setQuestion(e.target.value) }} className="title" placeholder="" required />
                </div>
                <div className="brief-input">
                    <label>Answer</label>
                    <textarea value={Answer} onChange={(e) => { setAnswer(e.target.value) }}>

                    </textarea>
                    {/* <input type="text" className="title" placeholder="" required /> */}
                </div>

                <div className='add_btn'>
                    <button className='btn' onClick={() => SaveFAQ()}>
                        Save FAQ
                    </button>
                </div>

                        <div className="two-btns">
                            {/* <button id="brief-reset">Reset</button> */}
                            <button id="brief-submit" onClick={() => { submit() }}>Submit Campagin</button>
                        </div>
            </div>
        </section>
    )
}
